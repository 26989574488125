import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';

const defaultLink = new HttpLink({
	uri: 'https://sitecoredxp-uat-cms.osfhealthcare.org/api/graph/ISC',
	headers: {
		sc_apikey: '{83B432B0-3E2B-41D5-BA34-46569D0C743D}',
		'Access-Control-Allow-Origin': '*',
	},
});

const edgeLink = new HttpLink({
	uri: 'https://sitecoredxp-dev-cms.osfhealthcare.org/sitecore/api/graph/edge',
	headers: {
		sc_apikey: '{83B432B0-3E2B-41D5-BA34-46569D0C743D}',
		'Access-Control-Allow-Origin': '*',
	},
});

const sitecoreItemLink = new HttpLink({
	uri: 'https://sitecoredxp-dev-cms.osfhealthcare.org/api/graph/providers',
	headers: {
		sc_apikey: '{83B432B0-3E2B-41D5-BA34-46569D0C743D}',
		'Access-Control-Allow-Origin': '*',
		'Apollo-Require-Preflight': 'true',
	},
});

const client = new ApolloClient({
	link: ApolloLink.split(
		(operation) => operation.getContext().clientName === 'searchByItemId',
		sitecoreItemLink, // <= apollo will send to this if clientName is "searchByItemId"
		ApolloLink.split(
			(operation) => operation.getContext().clientName === 'searchOnEdge',
			edgeLink, // <= apollo will send to this if clientName is "anotherClientName"
			defaultLink // <= otherwise will send to this
		)
	),
	cache: new InMemoryCache(),
});

export default client;
